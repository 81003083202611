import type {
	ActiveCategoryResponse,
	CollectionResponse,
	LinkResponse,
	PropertyGroupResponse,
	SliderCollectionResponse,
} from "@/data/__generated__/types/generated";
import type { TabsType } from "@/types";

import dynamic from "next/dynamic";

import ItemCollectionList from "../ItemCollectionList";
import DesktopPolygonTiles from "./components/DesktopPolygonTyles";
import InfoSection from "./components/InfoSection";
import MobilePolygonTiles from "./components/MobilePolygonTyles";
import Slider from "./components/Slider";
import SliderMobileText from "./components/SliderMobileText";

const HomeCollectionList = dynamic(() => import("./components/HomeCollectionList"), {
	ssr: false,
});

type HomeViewProps = {
	activeCategory: ActiveCategoryResponse;
	homeLinks?: LinkResponse[];
	listType?: TabsType;
	popularCollections?: CollectionResponse[];
	popularLoading?: boolean;
	setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
	slideIndex: number;
	sliderCollections: SliderCollectionResponse[];
	ssrCategoryFilters?: PropertyGroupResponse[];
};

const HomeView: React.FC<HomeViewProps> = ({
	sliderCollections,
	slideIndex,
	setSlideIndex,
	homeLinks,
	popularCollections,
	popularLoading,
	activeCategory,
	listType,
	ssrCategoryFilters,
}) => {
	return (
		<div className="my-4 mb-[120px] sm:mb-12">
			<div className="flex gap-6 max-h-[500px] overflow-hidden sm:max-h-max sm:flex-col sm:gap-2">
				<Slider sliderCollections={sliderCollections} slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
				<SliderMobileText activeSlide={sliderCollections[slideIndex]} />
			</div>
			<DesktopPolygonTiles homeLinks={homeLinks} />
			<MobilePolygonTiles homeLinks={homeLinks} />
			<div className="mt-20">
				<ItemCollectionList
					categoryLft={activeCategory.lft}
					categoryRgt={activeCategory.rgt}
					categorySlug={activeCategory.slug}
					categoryTitle={activeCategory.header || activeCategory.title}
					listType={listType}
					ssrCollections={[]}
					ssrProducts={[]}
					ssrSelectedFilters={null}
					queryLimit={12}
					ssrCategoryFilters={ssrCategoryFilters}
					asH2
				/>
			</div>
			<HomeCollectionList collections={popularCollections} isLoading={popularLoading} title="Популярные коллекции" />
			<InfoSection />
		</div>
	);
};

export default HomeView;
