import type { LinkResponse } from "@/data/__generated__/types/generated";

import NextLink from "next/link";

import { getImageSource } from "@/helpers";
import { useIsMobile } from "@/hooks/use-mobile";
import { cn } from "@/libs/utils";

type MobilePolygonTylesProps = {
	homeLinks?: LinkResponse[] | null;
	homeLinksLoading?: boolean;
};

type TilesRowProps = {
	links: LinkResponse[];
	startOffset: number;
	topClass: string;
};

type TileProps = {
	indexOffset: number;
	link: LinkResponse;
	positionOffset: number;
};

const PlaceholderTile: React.FC<{
	indexOffset: number;
}> = ({ indexOffset }) => {
	return (
		<div className={`mobile-polygon-${indexOffset} bg-mediumGray w-[384px] overflow-hidden relative`}>
			<div className="w-full h-full animate-pulse bg-mediumGray" />
		</div>
	);
};

const Tile: React.FC<TileProps> = ({ link, indexOffset, positionOffset }) => {
	const imageSource = getImageSource({
		height: 656,
		width: 800,
		type: "links",
		thumbnail: link.image,
	});

	return (
		<div className={`mobile-polygon-${indexOffset} bg-mediumGray w-full overflow-hidden cursor-pointer relative`}>
			<NextLink href={link.url} className="group" prefetch={true}>
				<div className="w-full h-full group-hover:scale-[1.1] group-hover:blur-sm transform transition duration-500 will-change-transform">
					<img src={imageSource} alt={link.title || "Tile image"} className="w-full h-full object-cover" />
				</div>
				<div
					className={cn(
						"left-1 bg-primaryBlack/50 max-w-[95%] py-1 px-3 rounded-xl backdrop-blur-sm w-max absolute group-hover:bg-primaryBlack/100 transition duration-500",
						positionOffset === 2 ? "bottom-1" : "top-1",
					)}
				>
					<span className="text-white font-medium text-sm">{link.title}</span>
				</div>
			</NextLink>
		</div>
	);
};

const TilesRow: React.FC<{ isLoading?: boolean } & TilesRowProps> = ({
	links,
	startOffset,
	topClass,
	isLoading = false,
}) => {
	return (
		<div className={`flex justify-between gap-2 absolute left-0 w-full ${topClass}`}>
			{isLoading
				? Array.from({ length: 2 }, (_, index) => (
						<PlaceholderTile key={`placeholder-${startOffset + index}`} indexOffset={startOffset + index} />
					))
				: links.map((link, index) => (
						<Tile key={link.id} link={link} indexOffset={startOffset + index} positionOffset={startOffset} />
					))}
		</div>
	);
};

const MobilePolygonTiles: React.FC<MobilePolygonTylesProps> = ({ homeLinks = [], homeLinksLoading }) => {
	const isMobile = useIsMobile();
	if (!isMobile) return null;
	return (
		<div className="hidden sm:flex sm:flex-col mt-12">
			<h1 className="text-2xl font-medium mb-6">Популярная керамическая плитка</h1>
			<div className="w-full relative min-h-[500px]">
				<TilesRow
					links={homeLinks?.slice(0, 2) as LinkResponse[]}
					startOffset={0}
					topClass="top-0"
					isLoading={homeLinksLoading}
				/>
				<TilesRow
					links={homeLinks?.slice(2, 4) as LinkResponse[]}
					startOffset={2}
					topClass="top-[110px]"
					isLoading={homeLinksLoading}
				/>
				<TilesRow
					links={homeLinks?.slice(4, 6) as LinkResponse[]}
					startOffset={0}
					topClass="top-[255px]"
					isLoading={homeLinksLoading}
				/>
				<TilesRow
					links={homeLinks?.slice(6, 8) as LinkResponse[]}
					startOffset={2}
					topClass="top-[365px]"
					isLoading={homeLinksLoading}
				/>
			</div>
		</div>
	);
};

export default MobilePolygonTiles;
