import { LabelInput } from "@/data/__generated__/types/generated";
import api from "@/data/api";

export const useHomePageApi = () => {
	const {
		getCollectionByLabel: { data: popularCollectionsData, loading: popularCollectionLoading },
	} = {
		getCollectionByLabel: api.collection.CollectionsByLabel({
			input: {
				limit: 8,
				label: LabelInput.Popular,
			},
		}),
	};

	return {
		popularCollectionLoading,
		popularCollectionsData,
	};
};
