"use client";

import type {
	ActiveCategoryResponse,
	LinkResponse,
	PropertyGroupResponse,
	SliderCollectionResponse,
} from "@/data/__generated__/types/generated";
import type { TabsType } from "@/types";

import { useState } from "react";

import View from "./View";
import { useHomePageApi } from "./hooks/useHomePageApi";

type HomeProps = {
	activeCategory?: ActiveCategoryResponse;
	listType?: TabsType;
	sliderCollections: SliderCollectionResponse[];
	ssrCategoryFilters?: PropertyGroupResponse[];
	ssrLinks?: LinkResponse[];
};

const Home: React.FC<HomeProps> = ({
	sliderCollections,
	activeCategory,
	listType,
	ssrCategoryFilters,
	ssrLinks = [],
}) => {
	const [slideIndex, setSlideIndex] = useState(0);

	const { popularCollectionLoading, popularCollectionsData } = useHomePageApi();

	return (
		<View
			sliderCollections={sliderCollections}
			slideIndex={slideIndex}
			setSlideIndex={setSlideIndex}
			homeLinks={ssrLinks}
			popularCollections={popularCollectionsData?.collectionsByLabel}
			popularLoading={popularCollectionLoading}
			activeCategory={activeCategory as ActiveCategoryResponse}
			listType={listType}
			ssrCategoryFilters={ssrCategoryFilters}
		/>
	);
};

export default Home;
